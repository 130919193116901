import { useEffect, useState, useCallback } from 'react'

const useFetch = (promise, setData, params, parser) => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)

  const fetchData = useCallback(async () => {
    setIsLoading(true)
    try {
      let data = await promise(params)
      if (parser) data = parser(data)
      setData(data)
      setError(false)
    } catch {
      setError(true)
    } finally {
      setIsLoading(false)
    }
  }, [promise, setData, params, parser])

  // Fetch data on mount
  useEffect(() => {
    fetchData()
  }, [fetchData])

  return { isLoading, setIsLoading, error, setError, refetch: fetchData }
}

export default useFetch
