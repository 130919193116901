export const NOTIFICATION_TYPES = [
  { value: 1, name: 'Tipo de Notificación' },
  { value: 2, name: 'banner' },
  { value: 3, name: 'popup' },
  { value: 4, name: 'mail' }
]

export const BANNER_TYPES = [
  { value: 1, name: 'normal' },
  { value: 2, name: 'notification' },
  { value: 2, name: 'permanente' }
]

export const BANNER_CONFIG = {
  normal: {
    color: 'bg-yellow',
    textColor: 'text-black',
    duration: 'normal'
  },
  permanente: {
    color: 'bg-red',
    textColor: 'text-white',
    duration: 'permanente'
  },
  notification: {
    color: 'bg-normal-pinflag',
    textColor: 'text-white',
    duration: 'normal'
  }
}

export const POPUP_CONFIG ={
  normal: {
    duration: 'normal'
  },
  permanente: {
    duration: 'permanente'
  }
}

export const NOTIFICATION_SECTIONS = [
  { name: 'Notificaciones Generales', label: 'Notificaciones Generales' },
  { name: 'Notificaciones Cobranza', label: 'Notificaciones Cobranza' }
]

export const NOTIFICATION_CATEGORIES = {
  Tickets: 'Tickets',
  Sales: 'Sales',
  Points: 'Points',
  Requests: 'Requests'
}
