import React, { useState } from 'react'

import { contentCopy } from '../../constants/icons'

export const CopyButton = ({ valueToCopy, customClass }) => {
  const [copied, setCopied] = useState(false)

  const handleCopy = async () => {
    if (valueToCopy) {
      await navigator.clipboard.writeText(valueToCopy)
      setCopied(true)
      setTimeout(() => setCopied(false), 500)
    }
  }

  return (
    <span className="relative ml-1 inline-flex items-center justify-center">
      <button
        type="button"
        onClick={handleCopy}
        className="text-black focus:outline-none"
      >
        <img src={contentCopy} alt="copy" className={`h-4 w-4 ${customClass}`}/>
      </button>
      {copied && (
        <span className="absolute top-[-1.5rem] left-0 rounded bg-black px-2 py-1 text-xs text-white shadow-lg">
          ¡Copiado!
        </span>
      )}
    </span>
  )
}

export default CopyButton
