import apiClient from '../../axiosConfig/apiClient'
import { REFUNDS_URL } from '../../constants/endpointUrls/api'

export const getRefunds = async () => {
  const response = await apiClient.get(REFUNDS_URL)

  return response.data
}

export const getRefund = async (params) => {
  const response = await apiClient.get(`/sale-refund/${params.id}`)

  return response.data
}


export const createRefund = async (refund) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  const response = await apiClient.post('/sale-refund', refund, config)

  return response.data
}

export const uploadDocumentRefund = async (id, document) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
  const response = await apiClient.post(`/sale-refund/${id}/document`, document, config)

  return response.data
}

export const sendMessageRefund = async (id, message) => {
  const response = await apiClient.post(`/sale-refund/${id}/message`, message)

  return response.data
}

export const downloadChargeNote = async (refundId, type) => {
  const response = await apiClient.get(`/sale-refund/${refundId}/charge-note`, { params: { type } })

  return response.data
}

export const changeRefundStatus = async (id, status) => {
  const response = await apiClient.put(`/sale-refund/${id}/status`, { status })

  return response.data
}

export const changeRefundNotes = async (id, notes) => {
  const response = await apiClient.put(`/sale-refund/${id}/notes`, { notes })

  return response.data
}

