import moment from 'moment'

export const shortDate = (longDate) => (longDate ? longDate.slice(0, 10) : '-')

export const formatDate = (date) => new Date(date).toLocaleString('es-CL', { timeZone: 'America/Santiago' })

export const getFormattedDate = (date) => {
  if (!date) return '-'
  const formattedDate = formatDate(date)
  return shortDate(formattedDate)
}

export const getFormattedDateWithoutTimezone = (date) => {
  if (!date) return '-'
  const formatedDate = moment(date).format('DD-MM-YYYY')
  return formatedDate
}

export const getTimeFromFormattedDate = (date) => (date ? date.slice(12, 20) : '-')

export const getTimeFromDate = (date) => {
  if (!date) return '-'
  const formattedDate = formatDate(date)
  return getTimeFromFormattedDate(formattedDate)
}

export const substractDate = (date, days) => {
  let newDate = new Date()
  newDate = date.getDate() - days
  date.setDate(newDate)
  return date.toLocaleDateString('en-CA')
}

export const getDaysDifference = (date1, date2) => {
  const miliSecondsPerDay = 1000 * 3600 * 24
  return (new Date(date1) - new Date(date2)) / miliSecondsPerDay
}

export const toLocalISOString = (date) => {
  const localDate = new Date(date - date.getTimezoneOffset() * 60000)

  return localDate.toISOString().slice(0, -1)
}

export const getLastDayOfMonth = (year, month) => new Date(year, month + 1, 0).getDate()

export const parseFirstDay = (date) => moment(date).startOf('day').format()

export const parseLastDay = (date) => moment(date).endOf('day').format()

export function formatDateWithDashesToSlashes(date) {
  if (date) {
 const [year, month, day] = date.split('-')
  return `${day}/${month}/${year}`
  }
 return '-'
}

export const getHourAndDate = (date) => {
  const dateObject = new Date(date)
  return moment(dateObject).format('HH:mm DD/MM')
}
