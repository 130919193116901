import React, { useMemo, useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import LoadingError from '../../components/shared/LoadingError'
import Loader from '../../components/shared/Loader'
import HeaderTitle from '../../components/shared/HeaderTitle'
import ROUTES from '../../constants/routes'
import { getRefund, changeRefundNotes } from '../../helpers/requests/refunds'
import useFetch from '../../hooks/useFetch'
import { SALES_DETAIL_ICONS, SALES_DETAIL_BUTTONS_ICONS } from '../../constants/icons'
import RefundHeader from '../../components/Refunds/RefundDetail/RefundHeader'
import { getFormattedDate } from '../../utils/dates'
import Products from '../../components/Refunds/RefundDetail/Products'
import TimeLine from '../../components/Refunds/RefundDetail/Timeline'

const RefundDetail = () => {
  const { refundId } = useParams()
  const [refund, setRefund] = useState(null)
  const [isEditingNotes, setIsEditingNotes] = useState(false)
  const [notes, setNotes] = useState('')
  const [originalNotes, setOriginalNotes] = useState('')
  const [isSavingNotes, setIsSavingNotes] = useState(false)

  const { push } = useHistory()

  const params = useMemo(() => ({ id: refundId }), [refundId])

  const { isLoading, error, refetch } = useFetch(getRefund, setRefund, params)

  useEffect(() => {
    if (refund) {
      setNotes(refund.notes ?? '')
      setOriginalNotes(refund.notes ?? '')
    }
  }, [refund])

  const handleEditClick = () => setIsEditingNotes(true)

  const handleCancelClick = () => {
    setIsEditingNotes(false)
    setNotes(originalNotes)
  }

  const handleSaveClick = async () => {
    setIsSavingNotes(true)
    await changeRefundNotes(refundId, notes)
    setOriginalNotes(notes)
    setIsEditingNotes(false)
    setIsSavingNotes(false)
    refetch()
  }

  if (error) return <LoadingError />

  if (isLoading || !refund) {
    return (
      <div className="mt-10">
        <Loader />
      </div>
    )
  }

  return (
    <div className="min-h-screen w-full bg-light-grey">
      <HeaderTitle title="Indemnizaciones" subtitle="Detalle" goBack={() => push(ROUTES.REFUNDS)} />
      <div className="mx-10 pb-4">
        <RefundHeader
          id={refund.id}
          refundStatus={refund.refundStatus}
          files={refund.SaleRefundFiles}
          chat={refund.SaleRefundChatMessages}
        />
        <div className="mt-4 grid grid-cols-3 gap-4">
          <div className="col-span-2">
            <div className="rounded-t border bg-white p-6">
              <div className="mb-4 flex gap-2 font-medium">
                <img src={SALES_DETAIL_ICONS.timeline} alt="" className="my-auto w-5" />
                Seguimiento
              </div>
              <TimeLine history={refund.SaleRefundStatusLog} currentStatus={refund.refundStatus} />
            </div>
            <div className="mt-4 grid grid-cols-2 gap-4">
              <div className="rounded border bg-white p-6">
                <div className="mb-2 flex gap-2 font-medium">
                  <img src={SALES_DETAIL_ICONS.detail} alt="" className="my-auto w-4" />
                  Detalle
                </div>
                <div className="text-s font-medium">O. Venta</div>
                <div className="text-s">{refund.orderId}</div>
                <div className="mt-2 text-s font-medium">O. Transporte</div>
                <div className="text-s">{refund.trackingId}</div>
                <div className="mt-2 text-s font-medium">Fecha de creación</div>
                <div className="text-s">{getFormattedDate(refund.createdAt)}</div>
                <div className="mt-2 text-s font-medium">Descripción</div>
                <div className="text-s">{refund.refundReason}</div>
              </div>
              <div className="rounded border bg-white p-6">
                <div className="mb-2 flex justify-between font-medium">
                  <div className="flex gap-2">
                    <img src={SALES_DETAIL_BUTTONS_ICONS.edit} alt="" className="my-auto w-4" />
                    Notas
                  </div>
                  {isEditingNotes ? (
                    <div className="flex gap-2">
                      <button
                        type="button"
                        className="font-medium text-pinflag hover:opacity-70"
                        onClick={handleSaveClick}
                        disabled={isSavingNotes}
                      >
                        Guardar
                      </button>
                      <button
                        type="button"
                        className="font-medium text-red"
                        onClick={handleCancelClick}
                        disabled={isSavingNotes}
                      >
                        Cancelar
                      </button>
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="font-medium text-pinflag hover:opacity-70"
                      onClick={handleEditClick}
                    >
                      Editar
                    </button>
                  )}
                </div>

                {isSavingNotes ? (
                  <div className="flex justify-center p-4">
                    <Loader />
                  </div>
                ) : (
                  <textarea
                    className="h-40 w-full rounded border p-2"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    disabled={!isEditingNotes}
                  />
                )}
              </div>

            </div>
            <div className="mt-4 grid grid-cols-2 gap-4">
              <div className="rounded border bg-white p-6">
                <div className="mb-2 flex gap-2 font-medium">
                  <img src={SALES_DETAIL_ICONS.person} alt="" className="my-auto w-4" />
                  Cliente
                </div>
                <div className="text-s font-medium">Nombre</div>
                <div className="text-s">{refund.Client?.name}</div>
                <div className="mt-2 text-s font-medium">Mail cliente</div>
                <div className="text-s">{refund.Client?.email}</div>
                <div className="mt-2 text-s font-medium">Teléfono</div>
                <div className="text-s">{refund.client?.phone}</div>
              </div>
              <div className="rounded border bg-white p-6">
                <div className="mb-2 flex gap-2 font-medium">
                  <img src={SALES_DETAIL_ICONS.payment} alt="" className="my-auto w-4" />
                  Pago
                </div>
                <div className="mt-2 text-s font-medium">Monto solicitado</div>
                <div className="text-s">{refund.claimedRefund}</div>
                <div className="mt-2 text-s font-medium">Información cuenta bancaria</div>
                <div className="mt-2 text-s font-medium">Información cuenta bancaria</div>
                  <div className="text-s">
                    {refund.bankAccountData
                      ? Object.values(refund.bankAccountData).map((accountData) => (
                          <div key={accountData}>{accountData}</div>
                        ))
                      : 'Información no disponible'}
                  </div>
              </div>
            </div>
          </div>
          <div>
            <div className="rounded border bg-white p-6">
              <div className="mb-2 flex gap-2 font-medium">
                <img src={SALES_DETAIL_ICONS.purchase} alt="" className="my-auto w-4" />
                Compra
              </div>
              <Products
                products={refund.saleProducts}
                refundProducts={refund.refundProducts}
                totalProductsAmount={refund.totalProductsAmount}
                saleDeliveryCost={refund.saleDeliveryCost}
                paidDeliveryCost={refund.paidDeliveryCost}
                totalProductsCost={refund.totalProductsCost}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RefundDetail
