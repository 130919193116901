export const API_URL = process.env.REACT_APP_API_URL

export const OPS_URL = process.env.REACT_APP_OPS_URL

export const STATS_API_URL = process.env.REACT_APP_STATS_API_URL

export const COMPANY_API_V2_URL = process.env.REACT_APP_COMPANY_API_V2_URL

export const PINFLAG_BOT_URL = process.env.REACT_APP_PINFLAG_BOT_URL

export const LOGIN_SUPER_URL = '/auth/login-super'

export const HOLDING_AGREEMENT_URL = '/holding-agreement'

export const STATES_URL = '/states/cities'

export const GET_PACKAGE_QR = (PACKAGE_ID) => `/company/package/${PACKAGE_ID}/qr`

export const PACKAGE_IN_TRANSIT_URL = '/company/package/status/in-transit'

export const PACKAGE_RECEPTION_URL = '/companies/couriers/package/reception'

export const PACKAGE_DELIVERED_URL = '/companies/couriers/package/delivered'

export const SIGNUP_COMPANY_URL = '/auth/signup-company'

export const TICKETS_URL = '/tickets'

export const SEND_MESSAGE_URL = (TICKET_ID) => `${TICKETS_URL}/${TICKET_ID}/messages`

export const CHANGE_TICKET_STATUS = (TICKET_ID, STATUS) => `/tickets/${TICKET_ID}/status/${STATUS}`

export const CLOSE_TICKET= (TICKET_ID) => `/tickets/${TICKET_ID}/close`

export const COMPANY_QUOTING = '/companies/delivery'

export const COURIERS_DATA_URL = '/couriers'

export const PROCESS_PACKAGES_URL = '/packages/packages-to-ops'

export const HANDLE_PACKAGES_URL = '/packages'

export const HANDLE_MISSING_PACKAGES_URL = '/missing-packages'

export const HANDLE_BILLS_URL = '/bills'

export const HANDLE_FILES_URL_OPS = '/files'

export const DOWNLOAD_DETAILS_OPS = '/download/details'

export const HANDLE_NOTIFICATIONS_URL = '/notifications'

export const HANDLE_NOTIFICATIONS_LOG_URL = '/notification-logs'

export const HANDLE_HOLDINGS_OPS_URL = '/holding'

export const SALES_URL = '/sales'

export const HOLDINGS_URL = '/common/holdings'

export const HOLDING_URL = 'holding/detail-bank-data'

export const HOLDING_BANK_URL = 'holding/detail-bank-data'

export const PACKAGE_STATS = '/stats/packages'

export const TICKETS_STATS = '/stats/tickets'

export const SLA_STATS = '/stats/waiting-times'

export const INCIDENTS_STATS = '/stats/incidents'

export const GET_PICKUP_SCHEDULES = '/companies/schedule/pick-up'

export const COURIER_SERVICES = '/courier-service/states'

export const ASSIGN_COURIER_SERVICE = '/courier-service/states/holding'

export const GET_COMPANIES_ALL = '/companies/all'

export const APPROVE_COMPANY = (companyId) => `/approve/${companyId}/company`

export const UPDATE_DELIVERY_CONDITIONS = (companyId, minWeight, maxWeight) =>
  `/delivery-condition?companyId=${companyId}&minWeight=${minWeight}&maxWeight=${maxWeight}`

export const DELIVERY_CONDITIONS = (companyId) => `/delivery-condition/${companyId}`

export const SAVE_MAP_DATA = (companyId) => `/map-data/${companyId}/warehouses`

export const REFUNDS_URL = '/sale-refund'

export const TICKET_NOTIFICATIONS_URL = '/notification'

export const READ_RELATED_NOTIFICATIONS = '/notification/read-related'
