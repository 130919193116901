/* eslint-disable tailwindcss/no-custom-classname */
import { React, useState } from 'react'

import NotificationsContainer from '../NotificationsContainer/NotificationsContainer'

import NotificationCenterWindowStyle from './NotificationCenterWindow.Style'

const NotificationCenterWindow = ({
  isOpen,
  handleClose,
  ticketsInfo
}) => {

  const [runAnimation, setRunAnimation] = useState(false)

  const notificationsTypes = {
    sales: 'Ventas',
    tickets: 'Solicitudes',
    points: 'Puntos'
  }

  const closeModal = () => {
    setRunAnimation(false)
    handleClose()
  }

  const waitCloseAnimation = () => {
    setRunAnimation(true)
    setTimeout(() => { closeModal() }, 900)
  }

  const clickOutside = (e) => {
    if (e.currentTarget !== e.target) return
    waitCloseAnimation()
  }

  return (
    <NotificationCenterWindowStyle>
      <div
        className={`nc-window-container fade-color-in ${!isOpen ? 'hidden' : ''} ${
          runAnimation ? 'fade-color-out' : ''
        }`}
        onClick={clickOutside}
        role="button"
        tabIndex="0"
      />
      <div
        className={`left-transition nc-window-modal ${isOpen ? '' : 'hidden'} ${
          runAnimation ? 'right-transition' : ''
        }`}
      >
        <div className="nc-window-modal-info">
          <div className="upper-notifications-container">
            <div className="upper-notifications-subcontainer">
              <div className="notifications-title">Notificaciones</div>
            </div>
            <div className="notifications-actions-container">
              <div className="notifications-actions-separator" />
              <div
                className="notifications-actions-button"
                onClick={clickOutside}
                role="button"
                tabIndex="0"
              >
                ✕
              </div>
            </div>
          </div>
          <div className="type-notifications-container">
            <div
              className="type-notifications-button"
              role="button"
              tabIndex="0"
            >
              <div>{notificationsTypes.tickets}</div>
              {ticketsInfo.unread > 0 && (
                <div className="unread-notifications">{ticketsInfo.unread}</div>
              )}
            </div>
          </div>
          <NotificationsContainer
            notifications={ticketsInfo}
            waitCloseAnimation={waitCloseAnimation}
          />
        </div>
      </div>
    </NotificationCenterWindowStyle>
  )
}

export default NotificationCenterWindow
