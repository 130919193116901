import { useCallback, useContext } from 'react'

import { getNotifications, readRelatedNotifications, readNotification } from '../helpers/requests/notifications'
import { notificationContext } from '../contexts/NotificationContext'

const useNotifications = () => {
  const { setNotifications, markNotificationsAsRead } =
    useContext(notificationContext)

  const updateNotifications = useCallback(async () => {
    try {
      const response = await getNotifications()
      setNotifications(response)
    } catch {
      throw new Error('Error al actualizar las notificaciones')
    }
  }, [setNotifications])

  const readNotificationsAndUpdate = useCallback(
    async (notificationIds, type, idCategory) => {
      markNotificationsAsRead(notificationIds, type)

      if (idCategory === null) {
        await Promise.all(
          notificationIds.map((notificationId) => readNotification(notificationId))
        )
      } else {
        await readRelatedNotifications({ category: type, idCategory })
      }

      return true
    },
    [markNotificationsAsRead]
  )

  return { updateNotifications, readNotificationsAndUpdate }
}

export default useNotifications
