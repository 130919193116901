import React, { useState, useContext } from 'react'

import { notificationContext } from '../../../contexts/NotificationContext'
import { readNotificationHelper } from '../../../helpers/notifications'
import { getTicket } from '../../../helpers/requests/tickets'
import useFetch from '../../../hooks/useFetch'
import useNotifications from '../../../hooks/useNotifications'
import Button from '../../shared/Button'
import Loader from '../../shared/Loader'

import Chat from './Chat'
import ChatContainerWindowStyle from './ChatContainerWindow.Style'
import ChatDetail from './ChatDetail'

const ChatContainerWindow = ({ ticketId, openChat, setOpenChat, unreadMessages }) => {
  const [ticket, setTicket] = useState({})
  const [runAnimation, setRunAnimation] = useState(false)
  const { isLoading, error } = useFetch(getTicket, setTicket, ticketId)

  const { readNotificationsAndUpdate } = useNotifications()
  const { ticketsInfo } = useContext(notificationContext)

  const closeSidebar = () => {
    setRunAnimation(true)
    setTimeout(() => {
      setRunAnimation(false)
      setOpenChat(false)
    }, 1000)
  }

  const clickOutside = async (e) => {
    if (e.currentTarget !== e.target) return
    closeSidebar()
    await readNotificationHelper(
      ticketsInfo,
      ticketId,
      readNotificationsAndUpdate
    )
  }

  return (
    <ChatContainerWindowStyle>
      {isLoading ? (
        <div className="flex h-full items-center justify-center">
          <Loader />
        </div>
      ) : !error && (
        <>
          <div
            // eslint-disable-next-line tailwindcss/no-custom-classname
            className={`sidebar-container ${!openChat ? 'hidden' : ''} ${
              runAnimation ? 'fade-out' : ''
            }`}
            onClick={clickOutside}
            role="button"
            tabIndex="0"
          />
          <div
            // eslint-disable-next-line tailwindcss/no-custom-classname
            className={`sidebar-modal ${openChat ? '' : 'hidden'} ${
              runAnimation ? 'slide-out' : ''
            } flex h-full flex-col overflow-hidden`}
          >
            <div className="flex h-full flex-col overflow-hidden bg-light-grey">
              <div className="relative m-6 flex flex-1 flex-col overflow-hidden rounded-xl bg-white">
                <div className='flex flex-row gap-x-3 pl-6 pt-4'>
                  <div className='flex w-1/2 flex-row items-center gap-x-3 pt-4'>
                    <div className="text-2xl font-medium">Chat</div>
                    {unreadMessages > 0 && (
                      <div className='rounded-full bg-red px-3 text-white'>
                      {unreadMessages} {unreadMessages === 1 ? 'mensaje nuevo' : 'mensajes nuevos'}
                      </div>
                    )}
                  </div>
                  <div className="flex w-1/2 flex-row-reverse">
                    <Button textColor="text-xl font-bold" onClick={closeSidebar}>
                      X
                    </Button>

                  </div>
                </div>
                <div className='flex flex-1 flex-row gap-6 overflow-hidden'>
                  <div className="mb-6 ml-6 mt-8 w-2/3 overflow-hidden">
                    <Chat
                      chatHistory={ticket.TicketChats}
                      ticketId={ticket.id}
                      unreadMessages={unreadMessages}
                      isInDetail={false}
                    />
                    </div>
                  <div className='mb-6 w-1/3 overflow-hidden'>
                    {ticket && <ChatDetail ticket={ticket} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </ChatContainerWindowStyle>
  )

}
export default ChatContainerWindow
