/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react'
import { Link } from 'react-router-dom'

const TableRow = ({ items, onClick, rowId, route, openRow, isSelected, excludeIndex = 8 }) => {

  const handleClick = (event) => {
    if (event.currentTarget !== event.target) return
    onClick()
  }
  if (route){

    const rawColor = isSelected ? 'bg-blue-100/40' : ''
    return (
      <tr className='w-full'>
        <td className={`w-8 ${rawColor}`} />
        {items.map((item, index) => (
          <td key={index} className={`whitespace-nowrap p-1 text-left ${rawColor}`}>
            {index !== excludeIndex ? (
              <Link
                to={`${route}/${rowId}`}
                className="block p-0"
                onClick={() => openRow(rowId)}
              >
                {item || '-'}
              </Link>
            ) : (
              item || '-'
            )}
          </td>
        ))}
      </tr>
    )}

  return (
    <tr className={`w-full ${onClick ? 'cursor-pointer hover:bg-light-grey' : ''}`}>
      <td className="w-8" onClick={handleClick} />
      {items.map((item, index) => (
        <td onClick={handleClick} key={index} className="whitespace-nowrap p-2 text-left">
          {item || '-'}
        </td>
      ))}
    </tr>
  )
}

export default TableRow
