import { React, useState, useCallback, useMemo, useEffect } from 'react'

import Loader from '../components/shared/Loader'
import Table from '../components/shared/Table/Table'
import TableRow from '../components/shared/Table/TableRow'
import Select from '../components/shared/Input/Select'
import { DEFAULT_COURIER, PINFLAG_PLAN_NAMES } from '../constants/general'
import EditGroupButton from '../components/shared/EditGroupButton'
import ConfirmModal from '../components/shared/Modal/ConfirmModal'
import InputTableFloat from '../components/shared/Input/InputTableFloat'
import useFetch from '../hooks/useFetch'
import { getActiveCouriers } from '../helpers/couriers'
import {
  getCouriersList,
  getRechargeMatrix,
  updateRechargeMatrix
} from '../helpers/requests/couriers'
import ResponseModal from '../components/shared/Modal/ResponseModal'

const RechargeMatrix = () => {
  const [charges, setCharges] = useState({})
  const [editedCharges, setEditedCharges] = useState({
    courier: '',
    pinflag_plan: '',
    charges: {}
  })
  const [courier, setCourier] = useState(DEFAULT_COURIER)
  const [plan, setPlan] = useState(PINFLAG_PLAN_NAMES[0])
  const [editing, setEditing] = useState(false)
  const [isOpenConfirm, setOpenConfirm] = useState(false)
  const [activeCouriers, setActiveCouriers] = useState([])
  const [openResponseModal, setOpenResponseModal] = useState(false)

  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [errorSubmit, setErrorSubmit] = useState(false)

  const setCouriers = useCallback((couriers) => {
    setActiveCouriers(couriers)
    setCourier(couriers[0].name)
  }, [])
  useFetch(getCouriersList, setCouriers, '', getActiveCouriers)

  const params = useMemo(() => ({ params: { courier, pinflag_plan: plan } }), [courier, plan])
  const chargesSetter = useCallback((data) => {
    setCharges(data.charges)
  }, [])

  const { isLoading, setIsLoading, error, setError } = useFetch(
    getRechargeMatrix,
    chargesSetter,
    params
  )

  const isEdited = (region, zone) =>
    !!(
      editedCharges.courier === courier &&
      editedCharges.pinflag_plan === plan &&
      editedCharges.charges[region] &&
      editedCharges.charges[region][zone] !== undefined
    )

  const getChargeValue = (region, zone, charge) =>
    isEdited(region, zone) ? editedCharges.charges[region][zone] : charge

  const getNewCharges = async () => {
    setIsLoading(true)
    try {
      const newCharges = await getRechargeMatrix(params)
      chargesSetter(newCharges)
      setEditedCharges({
        courier,
        pinflag_plan: plan,
        charges: {}
      })
    } catch {
      setError(true)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSubmit = async () => {
    setEditing(false)
    setIsLoadingSubmit(true)
    try {
      await updateRechargeMatrix(editedCharges)
      setOpenConfirm(false)
      setErrorSubmit(false)
    } catch {
      setErrorSubmit(true)
    } finally {
      setIsLoadingSubmit(false)
      setOpenConfirm(false)
      setOpenResponseModal(true)
    }
  }

  const handleCancel = () => {
    if (Object.keys(editedCharges.charges).length > 0) {
      window.location.reload()
    }
  }

  const changeCharges = (region, zone, value) => {
    setEditedCharges({
      ...editedCharges,
      charges: {
        ...editedCharges.charges,
        [region]: {
          ...editedCharges.charges[region],
          [zone]: parseFloat(value)
        }
      }
    })
  }

  const isZoneDisabled = (zone) => {
    if (zone === undefined) return true
    return false
  }

  useEffect(() => {
    setEditedCharges({
      courier,
      pinflag_plan: plan,
      charges: {}
    })
  }, [courier, plan])

  return (
    <div className="m-10">
      <div className="mb-8 text-left text-xl font-semibold">Matriz de Recarga</div>
      <div className="mb-12 flex">
        <div className=" w-1/3">
          <Select options={activeCouriers} label="Courier" setter={setCourier} withName />
        </div>
        <div className="mx-8 w-1/3">
          <Select options={PINFLAG_PLAN_NAMES} label="Planes" setter={setPlan} />
        </div>
      </div>
      {!isLoading && !error && (
        <Table headers={['Región', 'Central', 'Periférica', 'Borde']} roundedTop>
          {charges &&
            Object.entries(charges).map(([region, information]) => (
              <TableRow
                key={information.code}
                items={[
                  region,
                  ...['central', 'peripheral', 'border'].map((zone) => (
                    <InputTableFloat
                      disabled={isZoneDisabled(information[zone]) || !editing}
                      value={getChargeValue(information.code, zone, information[zone])}
                      onChange={(e) => changeCharges(information.code, zone, e.target.value)}
                      isEdited={isEdited(information.code, zone)}
                    />
                  ))
                ]}
              />
            ))}
        </Table>
      )}
      {isLoading && <Loader />}
      {error && <div>Ha ocurrido un error. Inténtelo más tarde.</div>}
      <EditGroupButton
        editing={editing}
        setEditing={setEditing}
        isEdited={Object.keys(editedCharges.charges).length > 0}
        handleCancel={handleCancel}
        handleSubmit={() => setOpenConfirm(true)}
      />
      <ConfirmModal
        handleSubmit={handleSubmit}
        show={isOpenConfirm}
        handleClose={() => setOpenConfirm(false)}
        isLoading={isLoadingSubmit}
      />
      <ResponseModal
        show={openResponseModal}
        handleClose={() => {
          setOpenResponseModal(false)
          getNewCharges()
        }}
        error={errorSubmit}
      />
    </div>
  )
}

export default RechargeMatrix
