import { ASSETS_URL } from './icons'

export const TICKETS_STATUS = [
  {
    value: 1,
    name: 'Creado'
  },
  {
    value: 2,
    name: 'En revisión'
  },
  {
    value: 3,
    name: 'Gestionado'
  },
  {
    value: 4,
    name: 'Resuelto'
  }
]

export const TICKET_STATES_INDEX = {
  Creado: 1,
  'En revisión': 2,
  Gestionado: 3,
  Resuelto: 4
}

export const STATUS_COLOR = {
  Creado: 'text-[#9747FF] bg-[#F7F0FF]',
  'En revisión': 'text-[#F0A155] bg-[#FFF2DF66]',
  Gestionado: 'text-[#0E9AFF] bg-[#0E9AFF14]',
  Resuelto: 'text-[#4EB03E] bg-[#34C24014]'
}

export const STATUS_ICONS = {
  Creado: `${ASSETS_URL}/tickets/created.png`,
  'En revisión': `${ASSETS_URL}/tickets/inReview.svg`,
  Gestionado: `${ASSETS_URL}/tickets/managed.png`,
  Resuelto: `${ASSETS_URL}/tickets/solved.png`
}

export const TYPE_OPTIONS = {
  'Pedido / Pedido con demora': 'Pedido con demora',
  'Pedido / Pedido perdido': 'Pedido perdido',
  'Pedido / Dirección incorrecta': 'Dirección incorrecta',
  'Pedido / Devolución a remitente': 'Devolución a remitente',
  'Pedido / Cambiar destino': 'Cambiar destino',
  'Pedido / Problemas con retiro': 'Problemas con retiro',
  'Pedido / Agendar Retiro': 'Agendar Retiro',
  'Pedido / Desconocimiento entrega': 'Desconocimiento entrega',
  'Indemnizaciones / Pieza faltante': 'Pieza faltante',
  'Indemnizaciones / Entrega con daño': 'Entrega con daño',
  'Otros / Falla en plataforma': 'Falla en plataforma',
  'Otros / Otras consultas': 'Otras consultas',
  'Otros / Problema en pago': 'Problema en pago'
}

export const MAX_DAYS_DIFF = 4

export const COURIER_ROUTES = {
  chilexpress: 'https://centrodeayuda.chilexpress.cl/seguimiento/',
  blueexpress: 'https://www.blue.cl/seguimiento/?n_seguimiento=',
  shippify: 'https://api.shippify.co/track/',
  globaltracking: 'https://globaltracking.cl/seguimiento/',
  recibelo: 'https://www.recibelo.cl/seguimiento/',
  urbano: 'https://portal.urbanoexpress.cl/rastrear-shipper/',
  fedex: 'https://clsclweb.tntchile.cl/txapgw/tracking.asp?boleto=',
  pickit :'https://tracking.pickit.cl/'
}

