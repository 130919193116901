export const capitalizeFirstLetter = (str) =>
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()

export const checkEmail = (email) => {
  // eslint-disable-next-line no-useless-escape
  const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/
  return email.match(emailFormat)
}

export const truncateString = (string, maxLength) => {
  if (string.length > maxLength) return `${string.slice(0, maxLength)}...`
  return string
}

export const isSubstring = (string, subString) => (
  string
    ?.trim()
    .toLowerCase()
    .includes(subString.trim().toLowerCase())
)

export const urlBase64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding)
    // eslint-disable-next-line no-useless-escape
    .replace(/\-/g, '+')
    .replace(/_/g, '/')

  const rawData = atob(base64)

  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; i += 1) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

