import React from 'react'
import { Link } from 'react-router-dom'

import { linkIcon, attachEmail } from '../../../constants/icons'
import ROUTES from '../../../constants/routes'
import { STATUS_COLOR } from '../../../constants/tickets'
import { getFormattedDate } from '../../../utils/dates'

import { SaleSearch, TrackingSearch } from './TicketsLinks'

const ChatDetail = ({ ticket }) => (
  <div className="flex h-full flex-col">
    <div className="relative mt-1 mr-6 flex h-full flex-col">
      <div className="sticky top-0 z-10 -mb-7 flex justify-center">
        <div className="flex h-14 w-14 items-center justify-center overflow-hidden rounded-full bg-pinflag-200">
          <img src={attachEmail} alt="attach email"/>
        </div>
      </div>
      <div className="flex h-full flex-1 flex-col gap-5 overflow-y-auto rounded-lg bg-light-grey p-6 pt-12 text-lg">
        <p className='text-xl'>
          Ticket #
          <Link to={`${ROUTES.TICKETS}/${ticket.id}`} className="inline-flex underline">
            {ticket.id}
            <img
              src={linkIcon}
              alt="link to ticket"
              className="ml-1 w-3 pt-1 brightness-0 invert"
            />
          </Link>
        </p>
        <div className={`flex w-max gap-2 rounded py-1 px-2 ${STATUS_COLOR[ticket.status]}
        w-1/2 items-center justify-center`}>
          {ticket.status}
        </div>
        <div className="flex flex-col gap-1 text-lg">
          <p className='font-medium'>Holding</p>
          <p className='text-medium-dark-grey'>{ticket.holdingName || '-'}</p>
        </div>

        {ticket.package.orderId && (
          <div className="flex flex-col gap-1">
            <p className='font-medium'>Orden de venta</p>
            <p className='text-medium-dark-grey'><SaleSearch searchValue={ticket.package.orderId} customClass="" /></p>
          </div>

        )}
        <div className="flex flex-col gap-1">
          <p className='font-medium'>Fecha</p>
          <p className='text-medium-dark-grey'>{getFormattedDate(ticket.createdAt)}</p>
        </div>
        {ticket.package.trackingNumber && (
          <div className="flex flex-col gap-1">
            <p className='font-medium'>Orden de transporte</p>
            <p className='text-medium-dark-grey'><TrackingSearch
              courier={ticket.package.courier}
              trackingId={ticket.package.trackingNumber}
              customClass=""
            /></p>
          </div>
        )}
        <div className="flex flex-col gap-1">
          <p className='font-medium'>Motivo</p>
          <p className='text-medium-dark-grey'>{ticket.type || '-'}</p>
        </div>
        {ticket.package.courier && (
          <div className="flex flex-col gap-1">
            <p className='font-medium'>Courier</p>
            <p className='text-medium-dark-grey'>{ticket.package.courier}</p>
          </div>
        )}
        <div className="flex flex-col gap-1">
          <p className='font-medium'>Actualización</p>
          <p className='text-medium-dark-grey'>{getFormattedDate(ticket.updatedAt)}</p>
        </div>
      </div>
    </div>
  </div>
)

export default ChatDetail
