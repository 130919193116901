import React, { useState } from 'react'

import useLocalStorage from '../../../hooks/useLocalStorage'
import { getFormattedDate } from '../../../utils/dates'
import { STATUS_COLOR, STATUS_ICONS, REFUNDS_STATES_TRANSLATION } from '../../../constants/refunds'
import { greenCheck, uploadFileIcon } from '../../../constants/icons'
import ROUTES from '../../../constants/routes'
import TableRow from '../../shared/Table/TableRow'
import Table from '../../shared/Table/Table'
import Button from '../../shared/Button'
import ToolTip from '../../shared/ToolTip'

import ChargeNote from './ChargeNote'
import SaleSearcher from './SaleSearcher'

const RefundsTable = ({ refunds, handleSort, isLoading, error, openCreate, setOpenCreate }) => {
  const [selectedRefund, setSelectedRefund] = useState({})
  const [openUploadFile, setOpenUploadFile] = useState(false)
  const [openRefundId, setOpenRefundId] = useLocalStorage('openRefundId', null)

  if (!isLoading &&  !error && refunds.length === 0) {
    return (
      <div className="mx-10 border-x border-b bg-white py-8 text-center text-s">
        No hay indemnizaciones con este criterio
      </div>
    )
  }

  const sortValues = {
    'ID': {key: 'id', type: 'number'},
    'Estado': {key: 'status', type: 'string'},
    'O. venta': {key: 'orderId', type: 'string'},
    'O. transporte': {key: '', type: 'string'},
    'Fecha': {key: 'createdAt', type: 'date'},
    'Empresa': {key: 'companyName', type: 'string'},
    'Courier': {key: 'courier', type: 'string'}
  }

  return (
    <>
    <Table
      maxHeight="max-h-[66vh]"
      sortValues={sortValues}
      sortData={handleSort}
      headers={['', 'ID', 'Estado', 'O. venta', 'O. transporte', 'Fecha', 'Empresa', 'Courier', '']}
      isLoading={isLoading}
      error={error}
    >
      {refunds?.map((refund) => (
        <TableRow
          key={refund.id}
          items={[
            (!refund.unsignedChargeNoteAvailable || refund.signedChargeNoteAvailable) ? (
                  <ToolTip
                    backgroundColor="bg-dark-pinflag"
                    textColor="text-white"
                    hoverElement={
                      <div className={`flex w-max gap-2 rounded-full p-1 ${refund.unsignedChargeNoteAvailable && refund.signedChargeNoteAvailable ? 'bg-green': 'bg-pinflag'}`} />
                    }
                    right
                  >
                    <div className="whitespace-normal">{refund.unsignedChargeNoteAvailable && refund.signedChargeNoteAvailable ? 'Nota de cobro firmada disponible' :'Debes cargar la nota de cobro'}</div>
                  </ToolTip>
                ) : (
                  ' '
                ),
            refund.id,
            <div
              className={
                `flex w-max gap-2 rounded py-1 px-2 ${STATUS_COLOR[refund.refundStatus]}`
              }
            >
              <img
                src={STATUS_ICONS[refund.refundStatus]}
                alt={refund.refundStatus}
                className="my-auto w-5"
              />
              {REFUNDS_STATES_TRANSLATION[refund.refundStatus]}
            </div>,
            refund.orderId,
            refund.trackingId,
            getFormattedDate(refund.createdAt),
            refund.companyName,
            refund.courierName || '-',
            <Button
              onClick={() => {
                setSelectedRefund(refund)
                setOpenUploadFile(true)
                setOpenRefundId(refund.id)
              }}
              border="border"
              textColor="text-medium-dark-grey"
              small
            >
              {refund.unsignedChargeNoteAvailable ? <img src={greenCheck} alt="upload file" className="my-auto w-5" />: <img src={uploadFileIcon} alt="upload file" className="my-auto w-5" />}
            </Button>
          ]}
          rowId={refund.id}
          route={ROUTES.REFUNDS}
          openRow={setOpenRefundId}
          isSelected={openRefundId === refund.id}
        />
      ))}
    </Table>
    <ChargeNote
      refundId={selectedRefund.id}
      openUploadFile={openUploadFile}
      setOpenUploadFile={setOpenUploadFile}
      unsignedChargeNoteAvailable={selectedRefund.unsignedChargeNoteAvailable}
      signedChargeNoteAvailable={selectedRefund.signedChargeNoteAvailable}
    />
    <SaleSearcher open={openCreate} handleClose={() => setOpenCreate(false)} />
    </>
  )
}

export default RefundsTable
