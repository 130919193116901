import styled from 'styled-components'

const NotificationCardStyled = styled.div`
    
    .notification-card-container {
        margin-bottom: 0.5rem;
        border-radius: 0.5rem;
        margin-right: 0.1rem;
        padding-left: 1.5rem;
    }

    .notification-card-container:hover {
        background-color: #F5F5F5;
        cursor: pointer;
    }

    .notification-card-container-readed {
        background-color: #EEF9F9;
    }

    .notification-card-container-info {
        display: flex;
        padding: 1.2rem;
    }

    .notification-card-icon {
        width: 4rem;
        height: 4rem;
        border-radius: 100%;
        overflow: hidden;
        background-color: #CFF2F2;
        margin-right: 1.2rem;
        display: flex;
        justify-content: center;
    }

    .notification-card-icon img {
        width: 2.2rem;
        height: 2.2rem;
        margin: auto;
    }

    .notification-card-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .notification-card-info-title {
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 0.2rem;
    }

    .notification-card-info-message {
        font-size: 0.8rem;
        font-weight: 300;
        color: #909AAA;
    }

    .notification-card-time {
        margin-left: auto;
        font-size: 0.8rem;
        font-weight: 300;
        color: #909AAA;
        text-align: right;
        width: 5rem;
    }

`
export default NotificationCardStyled
