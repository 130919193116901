import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import Button from '../../shared/Button'
import { duplicateOrderIcon, refundIcon, TICKET_ICONS, uploadIcon } from '../../../constants/icons'
import { getFormattedDate } from '../../../utils/dates'
import Modal from '../../shared/Modal/Modal'
import FileViewer from '../../shared/FileViewer'
import UploadFile from './UploadFile'
import ChangeStatus from './ChangeStatus'
import { STATUS_COLOR } from '../../../constants/tickets'

import { SaleSearch } from './TicketsLinks'
import Chat from './Chat'
import ROUTES from '../../../constants/routes'
import DuplicateOrder from '../../Sale/DuplicateOrder'

const Header = ({ ticket, unreadMessages, setUnreadMessages }) => {
  const [openChat, setOpenChat] = useState(false)
  const [openFiles, setOpenFiles] = useState(false)
  const [openChangeStatus, setOpenChangeStatus] = useState(false)
  const [openUploader, setOpenUploader] = useState(false)
  const [openActions, setOpenActions] = useState(false)
  const [openOrderDuplication, setOpenOrderDuplication] = useState(false)

  const actionsRef = useRef(null)

  const [filesResponse, setFilesResponse] = useState(ticket.TicketFiles)

  const [selectedFilter, setSelectedFilter] = useState([])

  const [filteredFiles, setFilteredFiles] = useState([])

  const { push } = useHistory()

  useEffect(() => {
    setSelectedFilter(filesResponse.filter(item => item.uploadedBy === 'ecommerce').length === 0 ? 'pinflag' : 'ecommerce')
    setFilteredFiles(
      filesResponse.filter(item => item.uploadedBy === 'ecommerce').length === 0 ?
      filesResponse.filter(item => item.uploadedBy === 'pinflag') :
      filesResponse.filter(item => item.uploadedBy === 'ecommerce')
    )
  }, [filesResponse])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (actionsRef.current && !actionsRef.current.contains(event.target)) {
        setOpenActions(false)
      }
    }

    if (openActions) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [openActions])

  const handleRefund = () => {
    push(`${ROUTES.CREATE_REFUND.split(':')[0]}${ticket.package.packageId}`)
  }

  const handleDuplicateOrder = () => {
    setOpenOrderDuplication(true)
  }

  return (
    <div className="flex flex-wrap justify-between gap-8 rounded-lg bg-white p-4">
      <div>
        <div
          className={`mb-1 py-1 px-4 text-sm font-medium 
          ${STATUS_COLOR[ticket.status]} w-fit rounded`}
        >
          {ticket.status}
        </div>
        <div className="font-medium">Solicitud #{ticket.id}</div>
      </div>
      <div className="flex gap-8 text-sm">
        {ticket.package && Object.keys(ticket.package).length > 0 && (
          <>
            <div>
              <div className="font-medium">O. de venta</div>
              <div className="text-medium-dark-grey">
                <SaleSearch searchValue={ticket.package.orderId}/>
              </div>
            </div>
            <div>
              <div className="font-medium">Nombre</div>
              <div className="text-medium-dark-grey">{ticket.package.customerName}</div>
            </div>
            <div>
              <div className="font-medium">Email</div>
              <div className="text-medium-dark-grey">{ticket.package.customerEmail}</div>
            </div>
          </>
        )}
        <div>
          <div className="font-medium">Fecha de creación</div>
          <div className="text-medium-dark-grey">{getFormattedDate(ticket.createdAt)}</div>
        </div>
      </div>
      <div className="my-auto flex gap-2">
        <Button color="bg-light-pinflag" small onClick={() => setOpenChangeStatus(true)}>
          <img src={TICKET_ICONS.switch} alt="archivos" className="h-6 py-1" />
        </Button>
        <Button color="bg-light-pinflag" small onClick={() => setOpenUploader(true)}>
          <img src={uploadIcon} alt="archivos" className="h-6 py-1" />
        </Button>
        {filesResponse.length > 0 && (
          <Button color="bg-light-pinflag" small onClick={() => setOpenFiles(true)}>
            <img src={TICKET_ICONS.files} alt="archivos" className="h-6 py-1" />
          </Button>
        )}
        <Button color="bg-pinflag" small onClick={() => setOpenChat(true)}>
          <img src={TICKET_ICONS.chat} alt="chat" className="h-6 py-1" />
        </Button>
        <div className="relative w-20" ref={actionsRef}>
          {openActions && (
            <div className="absolute left-[-150%] top-[-150%] z-10 border-2 bg-white p-2 drop-shadow-lg">
              <button
                type="button"
                onClick={() => handleRefund()}
                className="block w-full px-2 py-1 text-left text-sm hover:bg-normal-grey"
              >
                <div className="flex items-center gap-2">
                  <img src={refundIcon} alt="enviar" className="h-6 py-1" />
                  Crear indemnización
                </div>
              </button>
              <button
                type="button"
                onClick={() => handleDuplicateOrder()}
                className="block w-full px-2 py-1 text-left text-sm hover:bg-normal-grey"
              >
                <div className="flex items-center gap-2">
                  <img src={duplicateOrderIcon} alt="enviar" className="h-6 py-1" />
                  Duplicar orden
                </div>
              </button>
            </div>
          )}
          <button
            type="button"
            onClick={() => setOpenActions(!openActions)}
            className="z-0 flex h-9 w-fit rotate-90 rounded border border-medium-gray px-3 text-base font-bold"
          >
            ...
          </button>
        </div>
      </div>
      <Modal show={openChat} handleClose={() => setOpenChat(false)}>
        <div className='flex w-1/2 flex-row items-center gap-x-3 pt-4'>
          <div className="text-2xl font-medium">Chat</div>
          {unreadMessages > 0 && (
            <div className='rounded-full bg-red px-3 text-white'>
            {unreadMessages} {unreadMessages === 1 ? 'mensaje nuevo' : 'mensajes nuevos'}
            </div>
          )}
        </div>
        <Chat chatHistory={ticket.TicketChats} ticketId={ticket.id}
        unreadMessages={unreadMessages} setUnreadMessages={setUnreadMessages} isInDetail/>
      </Modal>
      <Modal title="Subir archivo" show={openUploader} handleClose={() => setOpenUploader(false)}>
        <UploadFile ticketId={ticket.id} setter={setFilesResponse}/>
      </Modal>
      {(filesResponse.length > 0 && filteredFiles.length > 0) && (
        <Modal title="Archivos adjuntos" show={openFiles} handleClose={() => setOpenFiles(false)}>
          <FileViewer
            files={filesResponse}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            filteredFiles={filteredFiles}
            setFilteredFiles={setFilteredFiles}
            urlGetter={(object) => object?.fileUrl}
            filters={['ecommerce', 'pinflag']}
          />
        </Modal>
      )}
      <Modal
        title="Cambiar estado"
        show={openChangeStatus}
        handleClose={() => setOpenChangeStatus(false)}
      >
        <ChangeStatus ticketId={ticket.id} status={ticket.status} />
      </Modal>
      <Modal
        title="Duplicar orden"
        show={openOrderDuplication}
        handleClose={() => setOpenOrderDuplication(false)}
      >
        <DuplicateOrder
          order={ticket.package}
        />
      </Modal>
    </div>
  )
}

export default Header
