/* eslint-disable tailwindcss/no-custom-classname */
import { React } from 'react'

import EmptyNotifications from '../EmptyNotifications/EmptyNotifications'
import NotificationCard from '../NotificationCard/NotificationCard'

import NotificationsContainerStyle from './NotificationsContainer.Style'

const NotificationsContainer = ({ notifications, waitCloseAnimation }) => {
  const isEmpty = Object.keys(notifications).length === 0
  if (isEmpty) return <EmptyNotifications />
  return (
    <NotificationsContainerStyle>
      <div className='notifications-container'>
        {notifications.notifications.map((notification) => (
          <NotificationCard
            notification={notification}
            key={notification.id}
            waitCloseAnimation={waitCloseAnimation}
          />
        ))}
      </div>
    </NotificationsContainerStyle>
  )
}

export default NotificationsContainer
