import React from 'react'
import { Link } from 'react-router-dom'

import ROUTES from '../../../constants/routes'
import { linkIcon } from '../../../constants/icons'
import { COURIER_ROUTES } from '../../../constants/tickets'
import { CopyButton } from '../../shared/CopyButton'

const couriersWithTrackingId = new Set([
  'blueexpress',
  'chilexpress',
  'urbano',
  'shippify',
  'pickit',
  'fedex'
])

export const TrackingSearch = ({ courier, trackingId, customClass }) => {
  const route = COURIER_ROUTES[courier]
  let href = null

  if (route) {
    if (couriersWithTrackingId.has(courier)) {
      href = `${route}${trackingId}`
    } else {
      href = route
    }
  }

  return (
    <div className="flex items-center">
      <span className={`inline-flex items-center ${customClass}`}>
        {href ? (
          <a
            href={href}
            className="inline-flex underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            {trackingId || '-'}
            <img src={linkIcon} alt="link" className={`ml-1 w-3 pt-1 ${customClass}`} />
          </a>
        ) : (
          <span>{trackingId || '-'}</span>
        )}
      </span>
      <CopyButton valueToCopy={trackingId} customClass={customClass} />
    </div>
  )
}

export const SaleSearch = ({ searchValue, customClass }) => (
  <div className="flex items-center">
    <Link
      to={`${ROUTES.SALE}?saleId=${searchValue}`}
      className={`inline-flex underline ${customClass}`}
    >
      {searchValue}
      <img src={linkIcon} alt="link" className={`ml-1 w-3 pt-1 ${customClass}`} />
    </Link>
    <CopyButton valueToCopy={searchValue} customClass={customClass} />
  </div>
)
