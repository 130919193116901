import styled from 'styled-components'

const NotificationCenterWindowStyle = styled.div`

    .nc-window-modal-info {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .nc-window-container {
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        height: 100%;
        max-height: 100vh;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 20;
    }

    .hidden {
        display: none;
    }

    .nc-window-modal {
        position: fixed;
        top: 0;
        left: 0;
        margin-left: 55%;
        height: 100%;
        width: 45%;
        border-radius: 2rem 0 0 2rem;
        background-color: white;
        z-index: 30;
    }

    .upper-notifications-container {
      margin-left: 2.5rem;
      margin-right: 2.5rem;
      margin-top: 2.5rem;
      margin-bottom: 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .upper-notifications-subcontainer {
      display: flex;
      align-items: center;
    }

    .notifications-title {
      font-size: 1.25rem;
      line-height: 1.75rem;
      font-weight: 500;
    }

    .notifications-beta {
      background-color: #EBF4F7;
      justify-content: center;
      align-items: center;
      display: flex;
      font-size: 0.8rem;
      font-weight: 500;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      border-radius: 3rem;
      margin-left: 1rem;
      color: #8497A5;
    }

    .type-notifications-container {
      display: flex;
      border-bottom: 2px solid #E5E5E5;
      padding-left: 2.5rem;
      padding-right: 2.5rem;
      text-align: left;
      font-weight: 300;
    }

    .type-notifications-button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 1.5rem;
      padding-bottom: 2px solid #E5E5E5;
      padding-left: 0.125rem;
      padding-right: 0.25rem;
      padding-bottom: 0.25rem;
      color: #909AAA;
      font-weight: 400;
      margin-bottom: -2px;
      &:hover {
        cursor: pointer;
      }
    }

    .unread-notifications {
      height: 1.2rem;
      width: fit-content;
      border-radius: 3rem;
      padding-left: 0.4rem;
      padding-right: 0.4rem;
      background-color: #EE4E4F;
      color: white;
      font-size: 0.8rem;
      font-weight: 300;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 0.5rem;
    }

    .type-notifications-button-active {
      color: #33cccc;
      border-bottom: 2px solid #33cccc;
    }

    .notifications-actions-container {
      display: flex;
      align-items: center;
    }

    .notifications-actions-button {
      display: flex;
      font-size: 1rem;
      font-weight: 600;
      height: 1.6rem;
      width: 1.6rem;
      text-align: center;
      align-items: center;
      justify-content: center;
      border-radius: 20%;
      &:hover {
        cursor: pointer;
        background-color: #E5E5E5;
      }
    }

    .notifications-actions-separator {
      height: 2rem;
      margin-left: 0.4rem;
      margin-right: 0.4rem;
      border-left: 1px solid #E5E5E5;
    }

    .left-transition {
        animation-duration: 1s;
        animation-name: slidein;
    }
      
    .right-transition {
        animation-duration: 1s;
        animation-name: slideout;
    }
      
    .fade-color-in {
        animation-duration: 1s;
        animation-name: fadeColorIn;
    }
      
    .fade-color-out {
        animation-duration: 1s;
        animation-name: fadeColorOut;
    }
      
      
    @keyframes fadeColorIn {
        from {
          background-color: rgba(0, 0, 0, 0);
        }
      
        to {
          background-color: rgba(0, 0, 0, 0.3);
        }
    }
      
    @keyframes fadeColorOut {
        from {
          background-color: rgba(0, 0, 0, 0.3);
        }
      
        to {
          background-color: rgba(0, 0, 0, 0);
        }
    }
      
    @keyframes slidein {
        from {
          margin-left: 100%;
        }
      
        to {
          margin-left: 55%;
        }
    }
      
    @keyframes slideout {
        from {
          margin-left: 55%;
        }
      
        to {
          margin-left: 100%;
        }
    }
`
export default NotificationCenterWindowStyle
