import React, { useState, useContext } from 'react'

import { TICKET_ICONS } from '../../../constants/icons'
import { notificationContext } from '../../../contexts/NotificationContext'
import { readNotificationHelper } from '../../../helpers/notifications'
import { sendTicketMessage } from '../../../helpers/requests/tickets'
import useNotifications from '../../../hooks/useNotifications'
import { formatDate } from '../../../utils/dates'

const Chat = ({ chatHistory, ticketId, unreadMessages, setUnreadMessages, isInDetail }) => {
  const [history, setHistory] = useState(
    [...chatHistory].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
  )
  const [messageTosend, setMessageToSend] = useState('')
  const [localUnreadMessages, setLocalUnreadMessages] = useState(unreadMessages)

  const { readNotificationsAndUpdate } = useNotifications()
  const { ticketsInfo } = useContext(notificationContext)

  const unreadIndices = [...Array(localUnreadMessages).keys()].map(
    (i) => history.length - localUnreadMessages + i
  )

  const sendMessage = async () =>  {
    if (messageTosend.trim() === '') return
    const responseData = await sendTicketMessage(ticketId, messageTosend)
    setHistory(history.concat(responseData))
    await readNotificationHelper(
      ticketsInfo,
      ticketId,
      readNotificationsAndUpdate
    )
    setUnreadMessages(0)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    sendMessage()
    setMessageToSend('')
    setLocalUnreadMessages(0)
  }

  return (
    <div className="flex h-full flex-col">
      <div className={`flex flex-col rounded-lg bg-light-grey p-2 ${
        isInDetail
          ? 'mt-4 max-h-[75vh] min-h-[30vh] w-[35rem] max-w-full gap-2'
          : 'flex-auto'
        } h-full`}>
        <div className="flex-1 overflow-y-auto">
          <div className="flex min-h-full flex-col justify-end">
            <div className="flex flex-col">
            {history.length === 0 && (
              <div className="my-4 text-center text-medium-gray">Aún no hay mensajes</div>
            )}
            {history.map((message, index) => (
              <div
                key={message.createdAt}
                className={`${
                  message.sender === 'ecommerce' ? 'mr-auto ml-2' : 'ml-auto mr-2 text-end'
                } mt-2 w-fit max-w-[65%]`}
              >
                <div
                  className={`w-fit overflow-hidden break-words rounded-b-2xl py-2
                  px-4 text-sm ${
                    message.sender === 'ecommerce'
                      ? 'ml-0 mr-auto rounded-tr-2xl bg-white'
                      : 'mr-0 ml-auto rounded-tl-2xl bg-pinflag text-white'
                  }`}
                >
                  {message.message}
                </div>
                <div className="flex items-center gap-2 text-xs text-dark-grey">
                  {unreadIndices.includes(index) && (
                    <div className="h-2 w-2 rounded-full bg-red" />
                  )}
                  {formatDate(message.createdAt)}
                </div>
              </div>
            ))}
            </div>
          </div>
        </div>
        <form className="relative m-2 flex shrink-0 items-center" onSubmit={handleSubmit}>
          <textarea
            className="h-fit w-full resize-y break-words rounded-2xl p-3 pr-6 text-sm shadow-lg"
            placeholder="Escribe un mensaje"
            value={messageTosend}
            onChange={(event) => setMessageToSend(event.target.value)}
            maxLength="500"
          />
          <button type="submit" className="absolute bottom-4 right-2">
            <img src={TICKET_ICONS.send} alt="enviar" className="h-6 py-1" />
          </button>
        </form>
      </div>
    </div>
  )
}

export default Chat
