/* eslint-disable tailwindcss/no-custom-classname */
import { React, useState, useEffect, useRef, useContext } from 'react'
import Draggable from 'react-draggable'

import { notificationContext } from '../../../contexts/NotificationContext'
import useNotifications from '../../../hooks/useNotifications'
import { askNotificationPermission } from '../../../serviceWorkerRegistration'
import NotificationCenterWindow from '../NotificationCenterWindow/NotificationCenterWindow'

import NotificationCenterStyle from './NotificationCenter.Style'

const NotificationCenter = () => {
  const [isOpen, setIsOpen] = useState(false)

  const nodeRef = useRef(null)

  const handleOpen = () => {
    setIsOpen(true)
    askNotificationPermission()
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const {
    salesInfo,
    ticketsInfo,
    pointsInfo,
    totalUnread
  } = useContext(notificationContext)

  const { updateNotifications } = useNotifications()

  useEffect(() => {
    updateNotifications()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <NotificationCenterStyle>
      <div className="notification-center-container">
        <Draggable nodeRef={nodeRef}>
          <div
            className="notification-center-popup"
            onClick={handleOpen}
            role="button"
            tabIndex="0"
            ref={nodeRef}>
            <div className="notifications-open-button" />
            {totalUnread > 0 && <div className="notifications-red-info"/>}
          </div>
        </Draggable>
      </div>
      <NotificationCenterWindow
        isOpen={isOpen}
        handleClose={handleClose}
        salesInfo={salesInfo}
        ticketsInfo={ticketsInfo}
        pointsInfo={pointsInfo} />
    </NotificationCenterStyle>
  )
}

export default NotificationCenter
