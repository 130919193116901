import styled from 'styled-components'

const ChatContainerWindowStyle = styled.div`
.sidebar-container {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    height: 100%;
    max-height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 20;
    animation-duration: 1s;
    animation-name: fadeColorIn;
  }

  .hidden {
    display: none;
  }

  .sidebar-modal {
    --end-margin-left: 50%; /* Modificado a la mitad de la pantalla */

    position: fixed;
    top: 0;
    left: 0;
    margin-left: var(--end-margin-left);
    height: 100%;
    width: 50%; /* Modificado para que ocupe la mitad */
    border-radius: 2rem 0 0 2rem;
    background-color: white;
    z-index: 30;
    animation-duration: 1s;
    animation-name: slideIn;

    @media (min-width: 1920px) {
      width: 50%; /* También en pantallas grandes */
    }
  }

  .fade-out {
    animation-name: fadeColorOut;
  }

  .slide-out {
    animation-name: slideOut;
  }

  @keyframes fadeColorIn {
    from {
      background-color: rgba(0, 0, 0, 0);
    }
    to {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  @keyframes fadeColorOut {
    from {
      background-color: rgba(0, 0, 0, 0.3);
    }
    to {
      background-color: rgba(0, 0, 0, 0);
    }
  }

  @keyframes slideIn {
    from {
      margin-left: 100%;
    }
    to {
      margin-left: var(--end-margin-left);
    }
  }

  @keyframes slideOut {
    from {
      margin-left: var(--end-margin-left);
    }
    to {
      margin-left: 100%;
    }
  }
`
export default ChatContainerWindowStyle
