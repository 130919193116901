import apiClient from '../../axiosConfig/apiClient'
import { READ_RELATED_NOTIFICATIONS, TICKET_NOTIFICATIONS_URL } from '../../constants/endpointUrls/api'

export const getNotifications = async () => {
  const response = await apiClient.get('/notification')

  return response.data
}

export const readNotification = async (notificationId) => {
  try {
    await apiClient.put(`/notification/${notificationId}/read`)

    return true
  } catch {
    return false
  }
}

export const readRelatedNotifications = async (body) => {
  try {
    const response = await apiClient.put(READ_RELATED_NOTIFICATIONS, body)

    return response.data
  } catch {
    return false
  }
}

export const getTicketsNotifications = async () => {
  const controller = new AbortController()
  const { signal } = controller

  try {
    const response = await apiClient.get(TICKET_NOTIFICATIONS_URL, { signal })
    return response.data
  } catch {
    if (!controller.signal.aborted) {
      throw new Error('Error fetching notifications')
    }
  }
  return null
}
